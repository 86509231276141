.swiper {
    width: 100%;
    padding-top: 50px;
   padding-bottom: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    max-width: 500px;

  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
   
  }